import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Main from "./Layout";
import { useAuth } from "../../common/useAuth";

const MyTasks = () => {
	const [jobList, setJobList] = useState([]);

	const { apiurl, token } = useAuth();

	useEffect(() => {
		fetchJobPosts();
	}, []);

	const fetchJobPosts = async () => {
		try {
			const response = await fetch(`${apiurl}/rec-job-postings/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const data = await response.json();
			setJobList(data);
		} catch (error) {
			console.error("Error fetching job posts:", error);
		}
	};

	const columns = [
		{
			title: "Client Name",
			dataIndex: ["username", "username"],
			key: "username",
			render: (username) => username || "N/A",
		},
		{
			title: "Job Title",
			dataIndex: "job_title",
			key: "job_title",
			render: (jobTitle) => jobTitle || "N/A",
		},
		{
			title: "Recruiter Assigned",
			dataIndex: "is_assigned",
			key: "is_assigned",
			render: (isAssigned) =>
				isAssigned ? isAssigned.username : "Not Assigned",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status) => status || "Unknown",
		},
		{
			title: "Actions",
			key: "actions",
			render: (_, record) => (
				<Link to={`${record.id}`}>View complete Details</Link>
			),
		},
	];

	return (
		<Main>
			{jobList && jobList.length > 0 ? (
				<Table
					columns={columns}
					dataSource={jobList}
					rowKey="id"
					className="custom-table"
					pagination={false}
				/>
			) : (
				<div className="no-postings">There are no postings</div>
			)}
		</Main>
	);
};

export default MyTasks;
