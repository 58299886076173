import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "antd";

import "./signup.css";

const SignupForm = ({ onToggle }) => {
	const navigate = useNavigate();

	return (
		<div className="container-signup left_form">
			<Typography.Title
				level={1}
				style={{ textAlign: "center", marginBottom: "1rem" }}>
				Sign up as
			</Typography.Title>
			<div className="role_buttons">
				<Button
					type="default"
					onClick={() => {
						navigate("/client/signup");
					}}>
					Client
				</Button>
				<Button
					type="default"
					onClick={() => {
						navigate("/agency/signup");
					}}>
					Agency
				</Button>
				<Button type="default" onClick={() => navigate("/candidate/signup")}>
					Job Seeker
				</Button>
				<div className="centered">
					<p>
						Already have an account?
						<Button
							type="text"
							onClick={onToggle}
							style={{
								width: 50,
								textDecoration: "none",
							}}>
							Login
						</Button>
					</p>
				</div>
			</div>
		</div>
	);
};

export { SignupForm };
