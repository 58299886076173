import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Modal, Spin } from "antd";
import { useAuth } from "../../common/useAuth";
import Main from "./Layout";
import './TermsAndConditions.css';

const OrganizationTerms = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [termsData, setTermsData] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		

		fetchTerms();
	}, [token]);

	const fetchTerms = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/organization-terms/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			const data = await response.json();
			if (!response.ok) {
				throw new Error(data.detail || "Failed to fetch organization terms");
			}

			setTermsData(data);
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/organization-terms/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});

			const data = await response.json();
			if (!response.ok) {
				throw new Error(data.detail || "Failed to update organization terms");
			}

			message.success("Organization terms updated successfully!");
			fetchTerms();
			setIsEditing(false);
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
		}
	};


	return (
		<Main>
			<div className="organization-terms-container">
				<h1 className="organization-terms-heading">Organization Terms</h1>

				<div className="terms-content">
					<p>
						<strong>Service Fee:</strong> {termsData?.service_fee}
					</p>
					<p>
						<strong>Replacement Clause:</strong> {termsData?.replacement_clause}
					</p>
					<p>
						<strong>Invoice After:</strong> {termsData?.invoice_after}
					</p>
					<p>
						<strong>Payment Within:</strong> {termsData?.payment_within}
					</p>
					<p>
						<strong>Interest Percentage:</strong>{" "}
						{termsData?.interest_percentage}%
					</p>
				</div>

				<Button
					type="primary"
					className="edit-terms-button"
					onClick={() => {
						form.setFieldsValue(termsData);
						setIsEditing(true);
					}}>
					Edit Terms
				</Button>

				<Modal
					title="Edit Organization Terms"
					open={isEditing}
					onCancel={() => setIsEditing(false)}
					footer={null}
					width={600}>
					<Form
						form={form}
						layout="vertical"
						onFinish={handleUpdate}
						initialValues={termsData}>
						<Form.Item
							label="Service Fee"
							name="service_fee"
							rules={[
								{ required: true, message: "Please input the service fee!" },
							]}>
							<Input type="number" />
						</Form.Item>

						<Form.Item
							label="Replacement Clause"
							name="replacement_clause"
							rules={[
								{
									required: true,
									message: "Please input the replacement clause!",
								},
							]}>
							<Input.TextArea rows={4} />
						</Form.Item>

						<Form.Item
							label="Invoice After"
							name="invoice_after"
							rules={[
								{ required: true, message: "Please input the invoice period!" },
							]}>
							<Input />
						</Form.Item>

						<Form.Item
							label="Payment Within"
							name="payment_within"
							rules={[
								{ required: true, message: "Please input the payment period!" },
							]}>
							<Input />
						</Form.Item>

						<Form.Item
							label="Interest Percentage"
							name="interest_percentage"
							rules={[
								{
									required: true,
									message: "Please input the interest percentage!",
								},
							]}>
							<Input type="number" />
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" loading={loading}>
								Update Terms
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		</Main>
	);
};

export default OrganizationTerms;
