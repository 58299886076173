import React from "react";
import "./Client.css";
import LayoutComp from "./Layout";

const Client = () => {
	return (
		<LayoutComp>
			<h2>Welcome</h2>
		</LayoutComp>
	);
};

export default Client;
