import React, { useEffect, useState } from "react";
import { Table, Button, Select, Form, Tabs, Modal } from "antd";
import { Link } from "react-router-dom";
import "./Recruiters.css";
import Main from "./Layout";
import AddRecruiters from "./AddRecruiters";
import { useAuth } from "../../common/useAuth";

const { Option } = Select;
const { TabPane } = Tabs;

const Recruiters = () => {
	const [recruiterSummary, setRecruiterSummary] = useState([]);
	const [workAllocationData, setWorkAllocationData] = useState([]);
	const [recruiterList, setRecruiterList] = useState([]);
	const [selectedRecruiters, setSelectedRecruiters] = useState({});
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		loadRecruiters();
		loadWorkAllocationData();
	}, []);

	const loadRecruiters = async () => {
		try {
			const response = await fetch(`${apiurl}/agency/recruiters/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setRecruiterSummary(data);
		} catch (error) {
			console.error("Error fetching recruiters:", error);
		}
	};

	const loadWorkAllocationData = async () => {
		try {
			const response = await fetch(`${apiurl}/api/manager/work_to_recruiter/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setWorkAllocationData(data.data);
			setRecruiterList(data.recruiter_data);
		} catch (error) {
			console.error("Error fetching work allocation data:", error);
		}
	};

	const saveRecruiterAllocation = async (jobId) => {
		const recruiterId = selectedRecruiters[jobId];
		if (recruiterId) {
			try {
				await fetch(`${apiurl}/api/manager/work_to_recruiter/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ jobId, recruiterId }),
				});
				loadWorkAllocationData();
			} catch (error) {
				console.error("Error assigning recruiter:", error);
			}
		}
	};

	const handleRecruiterSelection = (recruiterId, jobId) => {
		setSelectedRecruiters((prev) => ({ ...prev, [jobId]: recruiterId }));
	};

	const renderRecruiterDropdown = (recruiterName, record) => {
		if (recruiterName) {
			return recruiterName;
		} else {
			return (
				<Form onFinish={() => saveRecruiterAllocation(record.id)}>
					<Select
						placeholder="Select Recruiter"
						onChange={(value) => handleRecruiterSelection(value, record.id)}
						style={{ width: "100%" }}>
						{recruiterList.map((recruiter) => (
							<Option key={recruiter.id} value={recruiter.id}>
								{recruiter.username}
							</Option>
						))}
					</Select>
					<Button type="primary" htmlType="submit">
						Save
					</Button>
				</Form>
			);
		}
	};

	const recruiterColumns = [
		{
			title: "Recruiter Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
	];

	const summaryColumns = [
		{
			title: "Recruiter",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Client",
			dataIndex: "client_name",
			key: "client_name",
		},
		{
			title: "Role",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "No. of Positions",
			dataIndex: "num_of_positions",
			key: "num_of_positions",
		},
		{
			title: "No. of Resumes Shared",
			dataIndex: "resumes_sent",
			key: "resumes_sent",
		},
		{
			title: "No. of Resources Selected",
			dataIndex: "resumes_accepted",
			key: "resumes_accepted",
		},
		{
			title: "Job Status",
			dataIndex: "status",
			key: "status",
		},
	];

	const workAllocationColumns = [
		{
			title: "Client Name",
			dataIndex: "username",
			key: "username",
		},
		{
			title: "Role",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "Primary Skills",
			dataIndex: "primary_skills",
			key: "primary_skills",
		},
		{
			title: "Secondary Skills",
			dataIndex: "secondary_skills",
			key: "secondary_skills",
		},
		{
			title: "Allocate Recruiter",
			dataIndex: "recruiter_name",
			key: "recruiter_name",
			render: renderRecruiterDropdown,
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Link to={`/job_post_details/${record.id}`}>View</Link>
			),
		},
	];

	return (
		<Main>
			<div>
				<Tabs defaultActiveKey="1">
					<TabPane tab="Recruiters" key="1">
						<div className="add-rec-btn">
							<Button onClick={() => setIsModalVisible(true)}>
								Add Recruiter
							</Button>
						</div>
						<Table
							columns={recruiterColumns}
							dataSource={recruiterSummary}
							rowKey="id"
							className="custom-table"
							pagination={false}
						/>
					</TabPane>
					<TabPane tab="Recruiter Summary" key="2">
						<Table
							columns={summaryColumns}
							dataSource={recruiterSummary}
							rowKey="id"
							className="custom-table"
							pagination={false}
						/>
					</TabPane>
					<TabPane tab="Work Allocated to Recruiters" key="3">
						<Table
							columns={workAllocationColumns}
							dataSource={workAllocationData}
							rowKey="id"
							className="custom-table"
							pagination={false}
						/>
					</TabPane>
				</Tabs>
				<Modal
					open={isModalVisible}
					onCancel={() => setIsModalVisible(false)}
					footer={null}>
					<AddRecruiters onclose={loadRecruiters} />
				</Modal>
			</div>
		</Main>
	);
};

export default Recruiters;
