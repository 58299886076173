import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import { Link } from "react-router-dom";
import JobStatus from "./JobStatus";
import "./MyJobPosts.css";
import { useAuth } from "../../common/useAuth";
import Main from "./Layout";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const MyJobPosts = () => {
	const [jobList, setJobList] = useState([]);
	const { token } = useAuth();
	
	useEffect(() => {
		fetchJobPosts();
	}, []);

	const numberFormat = (value) => {
		let numberString = value.replace(/[^0-9.]/g, "");
		const numericValue = parseFloat(numberString);
		return isNaN(numericValue)
			? ""
			: new Intl.NumberFormat("en-IN", {
					style: "currency",
					currency: "INR",
			}).format(numericValue);
	};

	const fetchJobPosts = async () => {
		try {
			const response = await fetch(`${apiurl}/client/job-postings/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch job posts");
			}
			const data = await response.json();
			setJobList(data);
			console.log("Posts", data);
		} catch (error) {
			message.error("Failed to fetch job posts. Please try again.");
		}
	};

	const columns = [
		{
			title: "Job ID",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Job Title",
			dataIndex: "job_title",
			key: "job_title",
		},
		{
			title: "CTC",
			dataIndex: "ctc",
			key: "ctc",
			render: (ctc) => numberFormat(ctc),
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<div className="action-btns">
					<Link to={`/client/complete_job_post/${record.id}`}>
						View Details
					</Link>
					<Link to={`/client/promote_candidates/${record.id}`}>
						View Responses
					</Link>
				</div>
			),
		},
	];

	return (
		<Main>
			<div className="job-postings-list-main">
				<div>
					<Link to="/client/postjob">Add New Post</Link>
				</div>
				<div>
					{jobList && jobList.length > 0 ? (
						<Table
							columns={columns}
							dataSource={jobList}
							rowKey="id"
							expandable={{
								expandedRowRender: (record) => <JobStatus job={record} />,
								rowExpandable: (record) => true,
							}}
							pagination={false}
						/>
					) : (
						<div className="no-postings">There are no postings</div>
					)}
				</div>
			</div>
		</Main>
	);
};

export default MyJobPosts;
