import React from "react";

import Main from "./Layout";

const Manager = () => {
	return (
		<Main>
			<div className="no_overflow">Manager</div>
		</Main>
	);
};

export default Manager;
