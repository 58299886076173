import "./App.css";
import { Route, Routes } from "react-router-dom";
import Forms from "./components/forms/forms";
import ClientSignup from "./components/forms/ClientSignup";
import ForgotPassword1 from "./components/forms/ForgotPassword1";
import SetPassword from "./components/forms/SetPassword";
import { AuthRoute } from "./components/common/AuthRoute";
import MainHome from "./components/Home/Main";
import MyJobPosts from "./components/dashboard/client/MyJobPosts";
import AgencySignUp from "./components/forms/AgencySignup";
import OrganizationTerms from "./components/dashboard/Manager/TermsAndConditions";
import JobPosting from "./components/dashboard/client/PostJob";
import SeeNegotiations from "./components/dashboard/Manager/Negotiations";
import JobPosts from "./components/dashboard/Manager/JobPosts";
import CompleteJobPost from "./components/dashboard/Manager/CompleteJobPost";
import Recruiters from "./components/dashboard/Manager/Recruiters";
import MyTask from "./components/dashboard/Recruiter/MyTask";
import CompleteJobPostRecruiter from "./components/dashboard/Recruiter/CompleteJobPosts";

const App = () => {
	return (
		<>
			<Routes>
				<Route path="/login" element={<Forms />} />
				<Route path="/forgot_password" element={<ForgotPassword1 />} />
				<Route path="/reset/:uuid/:token" element={<SetPassword />} />
				<Route path="/client/signup" element={<ClientSignup />} />
				<Route path="/agency/signup" element={<AgencySignUp />} />
				<Route path="/" element={<AuthRoute />}>
					<Route path="/" element={<MainHome />} />
					<Route path="/client/mypostings" element={<MyJobPosts />} />
					<Route path="/client/postjob" element={<JobPosting />} />
					<Route path="/agency/terms" element={<OrganizationTerms />} />
					<Route path="/agency/postings" element={<JobPosts />} />
					<Route path="/agency/postings/:id" element={<CompleteJobPost />} />
					<Route path="/agency/negotiations" element={<SeeNegotiations />} />
					<Route path="/agency/recruiters" element={<Recruiters />} />
					<Route path="/recruiter/postings" element={<MyTask />} />
					<Route path="/recruiter/postings/:id" element={<CompleteJobPostRecruiter />} />
				</Route>
			</Routes>
		</>
	);
};

export default App;
