import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "./CalendarManage.css";
import { useAuth } from "../../common/useAuth";
const { Option } = Select;
const apiurl = process.env.REACT_APP_BACKEND_URL;

const UploadData = (jobData) => {
	const { authToken } = useAuth();
	const [candidates, setCandidates] = useState([createEmptyCandidate()]);
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [useResumeBank, setUseResumeBank] = useState(null);
	const [resumes, setResumes] = useState([]);
	const [error, setError] = useState("");

	useEffect(() => {
		initializeSkills();
	}, []);

	const initializeSkills = (jobData) => {
		if (jobData) {
			const primarySkills = jobData.primary_skills
				? jobData.primary_skills.split(",").map((skill) => skill.trim())
				: [];
			const secondarySkills = jobData.secondary_skills
				? jobData.secondary_skills.split(",").map((skill) => skill.trim())
				: [];
			const skillset = {
				primary_skills: primarySkills.reduce(
					(acc, skill) => ({ ...acc, [skill]: "" }),
					{}
				),
				secondary_skills: secondarySkills.reduce(
					(acc, skill) => ({ ...acc, [skill]: "" }),
					{}
				),
			};
			setCandidates([createEmptyCandidate(id, skillset)]);
		}
	};

	const fetchResumesFromBank = async () => {
		try {
			const response = await fetch(`${apiurl}/api/recruiter/get_resume_bank/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});
			if (!response.ok) {
				console.error("There is an error");
				alert("Error fetching resume data");
			} else {
				const data = await response.json();
				setResumes(data.data);
				console.log(data);
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (useResumeBank) {
			fetchResumesFromBank();
		}
	}, [useResumeBank]);

	const handleResumeBank = () => {
		setUseResumeBank(true);
	};

	const handleLocalInput = () => {
		setUseResumeBank(false);
	};

	const handleResumeSelection = (resume) => {
		console.log("Selected resume:", resume);

		const updatedCandidates = candidates.map((candidate, index) => {
			if (index === 0) {
				return {
					...candidate,
					candidate_name: `${resume.first_name} ${resume.middle_name} ${resume.last_name}`,
					candidate_email: resume.email,
					current_organisation: resume.current_organisation,
					job_status: resume.job_status,
					current_job_type: resume.current_job_type,
					current_job_location: resume.current_job_location,
					date_of_birth: resume.date_of_birth,
					total_years_of_experience: resume.total_years_of_experience,
					current_ctc: resume.current_ctc,
					expected_ctc: resume.expected_ctc,
					notice_period: resume.notice_period,
					joining_days_required: resume.joining_days_required,
					highest_qualification: resume.highest_qualification,
					contact_number: resume.contact_number,
					alternate_contact_number: resume.alternate_contact_number,
					resume: resume.resume,
					resumeFileList: [
						{
							uid: "-1",
							name: "resume.pdf",
							status: "done",
							url: resume.resume,
						},
					],
				};
			}
			return candidate;
		});

		setCandidates(updatedCandidates);
		setUseResumeBank(false);
	};

	const handleChange = (index, name, value) => {
		const updatedCandidates = candidates.map((candidate, i) => {
			if (i === index) {
				const [skillCategory, skillName] = name.split("__");
				if (
					skillCategory &&
					skillName &&
					candidate.skillset[skillCategory]?.hasOwnProperty(skillName)
				) {
					return {
						...candidate,
						skillset: {
							...candidate.skillset,
							[skillCategory]: {
								...candidate.skillset[skillCategory],
								[skillName]: value,
							},
						},
					};
				}
				return { ...candidate, [name]: value };
			}
			return candidate;
		});
		setCandidates(updatedCandidates);
	};

	const handleFileChange = (index, fileList) => {
		const updatedCandidates = candidates.map((candidate, i) =>
			i === index
				? {
						...candidate,
						resumeFileList: fileList,
						resume: fileList[0]?.originFileObj,
				  }
				: candidate
		);
		setCandidates(updatedCandidates);
	};

	const addCandidate = () => {
		const skillset = candidates[0].skillset;
		setCandidates([...candidates, createEmptyCandidate(id, skillset)]);
	};

	const removeCandidate = (index) => {
		if (candidates.length > 1) {
			const updatedCandidates = candidates.filter((_, i) => i !== index);
			setCandidates(updatedCandidates);
		}
	};

	const onBack = () => {
		navigate(-1);
	};

	const isValidContact = (number) => {
		number = number.trim();
		number = String(number);
		const pattern = /^[0-9]{10}$/;
		return pattern.test(number);
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		console.log(candidates);
		for (let candidate of candidates) {
			if (isValidContact(candidate.contact_number)) {
				const formData = new FormData();
				Object.keys(candidate).forEach((key) => {
					if (key === "skillset") {
						formData.append(key, JSON.stringify(candidate[key]));
					} else if (key === "resumeFileList") {
					} else {
						formData.append(key, candidate[key]);
					}
				});

				try {
					const response = await fetch(`${apiurl}/api/candidates_data/${id}/`, {
						method: "POST",
						headers: {
							Authorization: `Bearer ${authToken}`,
						},
						body: formData,
					});

					setLoading(false);
					if (!response.ok) {
						throw new Error("Failed to submit candidate data");
					}
					alert("Data submitted successfully");
					setError("");
					setCandidates([createEmptyCandidate(id, candidates[0].skillset)]);
					console.log("Candidate data submitted successfully");
				} catch (error) {
					setLoading(false);
					console.error("Error submitting candidate data:", error);
				}
			} else {
				setLoading(false);
				setError("Check the format of contact numbers");
			}
		}
	};

	return (
		<div>
			{useResumeBank === null ? (
				<div>
					<p>Do you want to upload from Resume Bank?</p>
					<Button onClick={handleResumeBank}>Yes</Button>
					<Button onClick={handleLocalInput}>No</Button>
				</div>
			) : useResumeBank ? (
				<div>
					<h2>Select a Resume from the Resume Bank</h2>
					{resumes.length > 0 ? (
						resumes.map((resume) => (
							<div key={resume.id}>
								<p>
									{resume.first_name} {resume.middle_name} {resume.last_name}
								</p>
								{resume.freeze === false && (
									<Button onClick={() => handleResumeSelection(resume)}>
										Select
									</Button>
								)}
								{resume.freeze === true && (
									<Button disabled={true}>Select</Button>
								)}
							</div>
						))
					) : (
						<p>No resumes available.</p>
					)}
				</div>
			) : (
				<div>
					<h1>Add Candidate</h1>
					{error && <p style={{ color: "red" }}>{error}</p>}
					<Form layout="vertical" className="rb-form" onFinish={handleSubmit}>
						{candidates.map((candidate, index) => (
							<div key={index}>
								<Form.Item label="Candidate Name" required>
									<Input
										value={candidate.candidate_name}
										onChange={(e) =>
											handleChange(index, "candidate_name", e.target.value)
										}
									/>
								</Form.Item>
								<Form.Item label="Candidate Email" required>
									<Input
										value={candidate.candidate_email}
										onChange={(e) =>
											handleChange(index, "candidate_email", e.target.value)
										}
									/>
								</Form.Item>
								<Form.Item label="Current Organisation">
									<Input
										value={candidate.current_organisation}
										onChange={(e) =>
											handleChange(
												index,
												"current_organisation",
												e.target.value
											)
										}
									/>
								</Form.Item>
								<Form.Item label="Job Status">
									<Select
										value={candidate.job_status}
										onChange={(value) =>
											handleChange(index, "job_status", value)
										}>
										<Option value="available">Available</Option>
										<Option value="not_available">Not Available</Option>
									</Select>
								</Form.Item>
								<Form.Item label="Current Job Type">
									<Select
										value={candidate.current_job_type}
										onChange={(value) =>
											handleChange(index, "current_job_type", value)
										}>
										<Option value="permanent">Permanent</Option>
										{}
										<Option value="contract">Contract</Option>
									</Select>
								</Form.Item>
								<Form.Item label="Current Job Location">
									<Input
										value={candidate.current_job_location}
										onChange={(e) =>
											handleChange(
												index,
												"current_job_location",
												e.target.value
											)
										}
									/>
								</Form.Item>
								<Form.Item label="Date of Birth">
									<Input
										type="date"
										value={candidate.date_of_birth}
										onChange={(e) =>
											handleChange(index, "date_of_birth", e.target.value)
										}
									/>
								</Form.Item>
								<Form.Item label="Total Years of Experience">
									<Input
										value={candidate.total_years_of_experience}
										onChange={(e) =>
											handleChange(
												index,
												"total_years_of_experience",
												e.target.value
											)
										}
									/>
								</Form.Item>
								{Object.entries(candidate.skillset).map(([category, skills]) =>
									Object.entries(skills).map(([skillName, value]) => (
										<Form.Item
											label={skillName}
											key={`${category}__${skillName}`}>
											<Select
												value={value}
												onChange={(newValue) =>
													handleChange(
														index,
														`${category}__${skillName}`,
														newValue
													)
												}>
												<Option value="0">0 years</Option>
												<Option value="1-2">1-2 years</Option>
												<Option value="2-3">2-3 years</Option>
												<Option value="3-4">3-4 years</Option>
												<Option value="4-5">4-5 years</Option>
												<Option value="5+ ">5+ years</Option>
												<Option value="N/A">N/A</Option>
											</Select>
										</Form.Item>
									))
								)}
								<Form.Item label="Current CTC">
									<Input
										value={candidate.current_ctc}
										onChange={(e) =>
											handleChange(index, "current_ctc", e.target.value)
										}
									/>
								</Form.Item>
								<Form.Item label="Expected CTC">
									<Input
										value={candidate.expected_ctc}
										onChange={(e) =>
											handleChange(index, "expected_ctc", e.target.value)
										}
									/>
								</Form.Item>
								<Form.Item label="Notice Period">
									<Input
										value={candidate.notice_period}
										onChange={(e) =>
											handleChange(index, "notice_period", e.target.value)
										}
									/>
								</Form.Item>
								<Form.Item label="Joining Days Required">
									<Input
										type="number"
										value={candidate.joining_days_required}
										onChange={(e) =>
											handleChange(
												index,
												"joining_days_required",
												e.target.value
											)
										}
									/>
								</Form.Item>
								<Form.Item label="Highest Qualification">
									<Input
										value={candidate.highest_qualification}
										onChange={(e) =>
											handleChange(
												index,
												"highest_qualification",
												e.target.value
											)
										}
									/>
								</Form.Item>
								<Form.Item label="Contact Number" required>
									<Input
										value={candidate.contact_number}
										onChange={(e) =>
											handleChange(index, "contact_number", e.target.value)
										}
									/>
								</Form.Item>
								<Form.Item label="Alternate Contact Number">
									<Input
										value={candidate.alternate_contact_number}
										onChange={(e) =>
											handleChange(
												index,
												"alternate_contact_number",
												e.target.value
											)
										}
									/>
								</Form.Item>
								<Form.Item label="Resume">
									<Upload
										fileList={candidate.resumeFileList}
										onChange={({ fileList }) =>
											handleFileChange(index, fileList)
										}
										beforeUpload={() => false}
										accept=".pdf,.doc,.docx">
										<Button icon={<UploadOutlined />}>Upload Resume</Button>
									</Upload>
								</Form.Item>
							</div>
						))}
						<Button type="primary" htmlType="submit" loading={loading}>
							Submit
						</Button>
					</Form>
				</div>
			)}
		</div>
	);
};

const createEmptyCandidate = (
	jobId = "",
	skillset = { primary_skills: {}, secondary_skills: {} }
) => ({
	job_id: jobId,
	candidate_name: "",
	candidate_email: "",
	current_organisation: "",
	job_status: "",
	current_job_type: "",
	current_job_location: "",
	date_of_birth: "",
	total_years_of_experience: "",
	skillset: skillset,
	current_ctc: "",
	expected_ctc: "",
	notice_period: "",
	joining_days_required: "",
	highest_qualification: "",
	contact_number: "",
	alternate_contact_number: "",
	resume: null,
	resumeFileList: [],
});

export default UploadData;
