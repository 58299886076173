import React, { useState } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	Steps,
	message,
	Modal,
	Slider,
} from "antd";
import { useAuth } from "../../common/useAuth";
import Main from "./Layout";
import "./PostJob.css";
import { useNavigate } from "react-router-dom";
import skillsList from "../../common/skills";

const { Step } = Steps;
const { Option } = Select;



const jobLevels = ["Entry", "Mid", "Senior", "Lead"];

const interviewModes = [
	{ value: "face_to_face", label: "Face-to-Face" },
	{ value: "online", label: "Online" },
	{ value: "telephone", label: "Telephone" },
];

const JobPosting = () => {
	const [form] = Form.useForm();
	const [currentStep, setCurrentStep] = useState(0);
	const [companyCode, setCompanyCode] = useState("");
	const [termsData, setTermsData] = useState(null);
	const [isNegotiating, setIsNegotiating] = useState(false);
	const { apiurl, token } = useAuth();
	const [ctcRange, setCtcRange] = useState([2, 4]);
	const [primarySkills, setPrimarySkills] = useState([]);
	const [secondarySkills, setSecondarySkills] = useState([]);
	const navigate = useNavigate();
	const [interviewRounds, setInterviewRounds] = useState([]);
	
	const [formValues, setFormValues] = useState({
		job_title: "",
		job_department: "",
		job_description: "",
		primary_skills: [],
		secondary_skills: [],
		years_of_experience: "",
		ctc: "",
		job_location: "",
		job_type: "",
		job_level: "",
		qualifications: "",
		timings: "",
		other_benefits: "",
		working_days_per_week: "",
		decision_maker: "",
		decision_maker_email: "",
		bond: "",
		rotational_shift: "",
	});

	const fetchOrganizationTerms = async () => {
		try {
			const response = await fetch(
				`${apiurl}/get-organization-terms/?org_code=${companyCode}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			const data = await response.json();
			if (!response.ok) {
				throw new Error(data.detail || "Failed to fetch organization terms");
			}
			setTermsData(data);
			message.success("Company terms fetched successfully!");
			setCurrentStep(1);
		} catch (error) {
			message.error(error.message);
		}
	};

	const handleCompanyCodeSubmit = () => {
		fetchOrganizationTerms();
	};

	const handleAgreeTerms = () => {
		setCurrentStep(2);
	};

	const handleNegotiateTerms = () => {
		setIsNegotiating(true);
	};

	const handleNegotiationSubmit = async (values) => {
		console.log("Negotiated Terms:", values);
		values = {...values,code:companyCode}
		try {
			const response = await fetch(`${apiurl}/negotiate-terms/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});

			const data = await response.json();
			if (!response.ok) {
				throw new Error(data.detail || "Failed to create job posting");
			}
			message.success("Negotiation raised successfully!");
			navigate('/');
			setIsNegotiating(false);
		} catch (error) {
			message.error(error.message);
		}
		
	};

	const handleNextStepSubmit = async (values) => {
		setFormValues(values);
		setCurrentStep(3);
	};

	const handleJobPostingSubmit = async (values) => {
		const ctc = `${ctcRange[0]} - ${ctcRange[1]} LPA`;
		const primarySkillsString = primarySkills.join(", ");
		const secondarySkillsString = secondarySkills.join(", ");


		const payload = {
			...values,
			formValues,
			acceptedterms : termsData,
			organization_code: companyCode,
			primary_skills: primarySkillsString,
			secondary_skills: secondarySkillsString,
			ctc,
			interview_rounds: interviewRounds,
		};

		try {
			const response = await fetch(`${apiurl}/job-postings/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(payload),
			});

			const data = await response.json();
			if (!response.ok) {
				throw new Error(data.detail || "Failed to create job posting");
			}
			message.success("Job posting created successfully!");
			navigate('/');
		} catch (error) {
			message.error(error.message);
		}
	};

	const handleNumRoundsChange = (value) => {
		setInterviewRounds(
			Array.from({ length: value }, (_, index) => ({
				round_num: index + 1,
				name: "",
				email: "",
				type_of_interview: "",
			}))
		);
	};

	const handleInterviewRoundChange = (index, field, value) => {
		const newRounds = [...interviewRounds];
		newRounds[index][field] = value;
		setInterviewRounds(newRounds);
	};

	return (
		<Main>
			<div className="organization-job-posting">
				<h1>Post a Job</h1>

				<Steps current={currentStep} className="steps-container">
					<Step title="Company Code" />
					<Step title="Agree to Terms" />
					<Step title="Job Posting" />
					<Step title="Interview Details" />
				</Steps>

				<div className="steps-content">
					{currentStep === 0 && (
						<div>
							<Form layout="vertical" onFinish={handleCompanyCodeSubmit}>
								<Form.Item
									label="Company Code"
									name="company_code"
									rules={[
										{ required: true, message: "Please enter company code" },
									]}>
									<Input
										value={companyCode}
										onChange={(e) => setCompanyCode(e.target.value)}
									/>
								</Form.Item>
								<Button type="primary" htmlType="submit">
									Fetch Terms
								</Button>
							</Form>
						</div>
					)}

					{currentStep === 1 && termsData && (
						<div className="companyterms">
							<h3>Company Terms</h3>
							{termsData ? (
								<>
									<p>
										<strong>Service Fee:</strong> {termsData?.service_fee}
									</p>
									<p>
										<strong>Replacement Clause:</strong>{" "}
										{termsData?.replacement_clause}
									</p>
									<p>
										<strong>Invoice After:</strong> {termsData?.invoice_after}
									</p>
									<p>
										<strong>Payment Within:</strong> {termsData?.payment_within}
									</p>
									<p>
										<strong>Interest Percentage:</strong>{" "}
										{termsData?.interest_percentage}%
									</p>
								</>
							) : (
								<p>Loading company terms...</p>
							)}

							<Button type="primary" onClick={handleAgreeTerms}>
								Agree to Terms
							</Button>
							<Button style={{ marginLeft: 10 }} onClick={handleNegotiateTerms}>
								Negotiate Terms
							</Button>

							<Modal
								title="Negotiate Terms"
								open={isNegotiating}
								onCancel={() => setIsNegotiating(false)}
								footer={null}>
								<Form
									form={form}
									layout="vertical"
									onFinish={handleNegotiationSubmit}>
									<Form.Item
										label="Service Fee"
										name="service_fee"
										initialValue={termsData?.service_fee}
										rules={[
											{ required: true, message: "Please enter service fee" },
										]}>
										<Input />
									</Form.Item>
									<Form.Item
										label="Replacement Clause"
										name="replacement_clause"
										initialValue={termsData?.replacement_clause}
										rules={[
											{
												required: true,
												message: "Please enter replacement clause",
											},
										]}>
										<Input.TextArea rows={4} />
									</Form.Item>
									<Form.Item
										label="Invoice After"
										name="invoice_after"
										initialValue={termsData?.invoice_after}
										rules={[
											{ required: true, message: "Please enter invoice after" },
										]}>
										<Input />
									</Form.Item>
									<Form.Item
										label="Payment Within"
										name="payment_within"
										initialValue={termsData?.payment_within}
										rules={[
											{
												required: true,
												message: "Please enter payment within",
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item
										label="Interest Percentage"
										name="interest_percentage"
										initialValue={termsData?.interest_percentage}
										rules={[
											{
												required: true,
												message: "Please enter interest percentage",
											},
										]}>
										<Input />
									</Form.Item>
									<Button type="primary" htmlType="submit">
										Submit Negotiated Terms
									</Button>
								</Form>
							</Modal>
						</div>
					)}

					{currentStep === 2 && (
						<Form
							form={form}
							layout="vertical"
							onFinish={handleNextStepSubmit}
							initialValues={formValues}>
							<Form.Item
								label="Job Title"
								name="job_title"
								rules={[{ required: true, message: "Please enter job title" }]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Job Department"
								name="job_department"
								rules={[
									{ required: true, message: "Please enter job department" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Job Description"
								name="job_description"
								rules={[
									{ required: true, message: "Please enter job description" },
								]}>
								<Input.TextArea rows={4} />
							</Form.Item>

							<Form.Item
								label="Primary Skills"
								name="primary_skills"
								rules={[
									{ required: true, message: "Please select primary skills" },
								]}>
								<Select
									mode="multiple"
									allowClear
									placeholder="Select primary skills"
									onChange={(value) => setPrimarySkills(value)}
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}>
									{skillsList.map((skill) => (
										<Option key={skill} value={skill}>
											{skill}
										</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label="Secondary Skills"
								name="secondary_skills"
								rules={[
									{ required: true, message: "Please select secondary skills" },
								]}>
								<Select
									mode="multiple"
									allowClear
									placeholder="Select secondary skills"
									onChange={(value) => setSecondarySkills(value)}
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}>
									{skillsList.map((skill) => (
										<Option key={skill} value={skill}>
											{skill}
										</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label="Years of Experience"
								name="years_of_experience"
								rules={[
									{
										required: true,
										message: "Please enter years of experience",
									},
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="CTC Range"
								name="ctc"
								>
								<Slider
									range
									min={1}
									max={50}
									defaultValue={ctcRange} 
									value={ctcRange} 
									onChange={setCtcRange}
								/>
								<div>
									<span>Min: {ctcRange[0]} LPA</span> &nbsp;{" "}
									<span>Max: {ctcRange[1]} LPA</span>
								</div>
							</Form.Item>

							<Form.Item
								label="Job Location"
								name="job_location"
								rules={[
									{ required: true, message: "Please enter job location" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Job Type"
								name="job_type"
								rules={[{ required: true, message: "Please enter job type" }]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Job Level"
								name="job_level"
								rules={[
									{ required: true, message: "Please select job level" },
								]}>
								<Select placeholder="Select job level">
									{jobLevels.map((level) => (
										<Option key={level} value={level}>
											{level}
										</Option>
									))}
								</Select>
							</Form.Item>

							<Form.Item
								label="Qualifications"
								name="qualifications"
								rules={[
									{ required: true, message: "Please enter qualifications" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Timings"
								name="timings"
								rules={[{ required: true, message: "Please enter timings" }]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Other Benefits"
								name="other_benefits"
								rules={[
									{ required: true, message: "Please enter other benefits" },
								]}>
								<Input.TextArea rows={3} />
							</Form.Item>

							<Form.Item
								label="Working Days Per Week"
								name="working_days_per_week"
								rules={[
									{
										required: true,
										message: "Please enter working days per week",
									},
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Decision Maker"
								name="decision_maker"
								rules={[
									{ required: true, message: "Please enter decision maker" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Decision Maker Email"
								name="decision_maker_email"
								rules={[
									{
										required: true,
										message: "Please enter decision maker email",
									},
									{ type: "email", message: "Please enter a valid email" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Bond"
								name="bond"
								rules={[
									{ required: true, message: "Please specify bond terms" },
								]}>
								<Input />
							</Form.Item>

							<Form.Item
								label="Rotational Shift"
								name="rotational_shift"
								rules={[
									{
										required: true,
										message: "Please specify rotational shift",
									},
								]}>
								<Select placeholder="Select shift option">
									<Option value="yes">Yes</Option>
									<Option value="no">No</Option>
								</Select>
							</Form.Item>
							<Button type="primary" onClick={() => setCurrentStep(1)}>
								Previous
							</Button>
							<Button
								style={{ marginLeft: 10 }}
								type="primary"
								htmlType="submit">
								Next Step
							</Button>
						</Form>
					)}

					{currentStep === 3 && (
						<div>
							<Form.Item
								label="Number of Interview Rounds"
								name="num_rounds"
								rules={[
									{ required: true, message: "Please enter number of rounds" },
								]}>
								<Input
									type="number"
									onChange={(e) => handleNumRoundsChange(e.target.value)}
								/>
							</Form.Item>

							{interviewRounds.map((round, index) => (
								<div key={index} style={{ marginBottom: 10 }}>
									<h4>Round {round.round_num}</h4>
									<Form.Item
										label="Interviewer Name"
										name={`round_${round.round_num}_name`}
										rules={[
											{
												required: true,
												message: "Please enter interviewer name",
											},
										]}>
										<Input
											value={round.name}
											onChange={(e) =>
												handleInterviewRoundChange(
													index,
													"name",
													e.target.value
												)
											}
										/>
									</Form.Item>
									<Form.Item
										label="Interviewer Email"
										name={`round_${round.round_num}_email`}
										rules={[
											{
												required: true,
												message: "Please enter interviewer email",
											},
										]}>
										<Input
											value={round.email}
											onChange={(e) =>
												handleInterviewRoundChange(
													index,
													"email",
													e.target.value
												)
											}
										/>
									</Form.Item>
									<Form.Item
										label="Interview Type"
										name={`round_${round.round_num}_type`}
										rules={[
											{
												required: true,
												message: "Please select interview type",
											},
										]}>
										<Select
											value={round.type_of_interview}
											onChange={(value) =>
												handleInterviewRoundChange(
													index,
													"type_of_interview",
													value
												)
											}>
											{interviewModes.map((mode) => (
												<Option key={mode.value} value={mode.value}>
													{mode.label}
												</Option>
											))}
										</Select>
									</Form.Item>
								</div>
							))}

							<Button type="primary" onClick={() => setCurrentStep(2)}>
								Previous
							</Button>
							<Button
								style={{ marginLeft: 10 }}
								type="primary"
								htmlType="submit"
								onClick={() => handleJobPostingSubmit(formValues)}>
								Submit Job Posting
							</Button>
						</div>
					)}
				</div>
			</div>
		</Main>
	);
};

export default JobPosting;
