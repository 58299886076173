import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import "./forms.css";
import { useNavigate, useParams } from "react-router-dom";
const apiurl = process.env.REACT_APP_BACKEND_URL;

const SetPassword = () => {
	const [form] = Form.useForm();
	const { uuid, token } = useParams();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (values) => {
		setLoading(true);

		if (values.password === values.confirm_password) {
			try {
				const response = await fetch(
					`${apiurl}/resetpassword/${uuid}/${token}/`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(values),
					}
				);
				setLoading(false);
				const data = await response.json();
				if (!response.ok) {
					message.error(data.error);
				}
				if (response.ok) {
					message.success(data.success);
					navigate("/login");
				}
			} catch (error) {
				console.error(error);
				message.error("Network error. Please Try again after sometime");
			}
		} else {
			message.error("Passwords Didn't Match");
			setLoading(false);
		}
	};
	return (
		<div className="no_overflow">
			<div className="topnav">
				<img
					src="../src/media/ga_orgsync.png"
					alt="Home page img"
					width={90}
					height={70}
					style={{ marginLeft: 30, marginTop: 5 }}
				/>
			</div>
			<h1 className="center heading1">
				Connecting professionals and forging <br /> career paths, one connection
				at a time."
			</h1>
			<div className=" client-signup">
				<Form form={form} layout="vertical" onFinish={handleSubmit}>
					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: "Enter password" }]}>
						<Input.Password className="login-input input_width" />
					</Form.Item>

					<Form.Item
						label="Confirm Password"
						name="confirm_password"
						rules={[{ required: true, message: "ReEnter password" }]}>
						<Input.Password className="login-input input_width" />
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							style={{ backgroundColor: "#001744" }}>
							Save Password
						</Button>
					</Form.Item>
				</Form>

				<div className="home_img center" style={{ paddingRight: 100 }}>
					<img
						src="../src/media/forgot_pwd.png"
						alt="email verification"
						className="home-img"
					/>
				</div>
			</div>
		</div>
	);
};

export default SetPassword;
