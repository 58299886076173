import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, message } from "antd";
import "./login.css";
import { useAuth } from "../common/useAuth";

const { Title, Paragraph } = Typography;

const apiurl = process.env.REACT_APP_BACKEND_URL;

export const LoginForm = ({ onToggle }) => {
	const [user, setUser] = useState({
		email: "",
		password: "",
	});

	const { handleLogin } = useAuth();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		try {
			
			setLoading(true);

			const response = await fetch(`${apiurl}/login/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(user),
			});

			const data = await response.json();

			if (response.ok) {
				if (data.error) {
					navigate("/verify");
					setLoading(false);
				} else {
					handleLogin(data.access_token);
					message.success("Login successful");
					setLoading(false);
					navigate("/");
				}
			} else {
				message.error(data.error);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			message.error(error.toString());
		}
	};

	return (
		<div className="login-container left_form">
			<Form name="login_form" onFinish={handleSubmit}>
				<Title level={2} style={{ textAlign: "center" }}>
					Login
				</Title>
				<Form.Item
					name="email"
					rules={[
						{ required: true, message: "Please input your Email!" },
						{ type: "email", message: "Please enter a valid Email!" },
					]}>
					<Input
						name="email"
						placeholder="Email"
						value={user.email}
						onChange={handleInputChange}
						className="login-input"
					/>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[{ required: true, message: "Please input your Password!" }]}>
					<Input.Password
						name="password"
						placeholder="Password"
						value={user.password}
						onChange={handleInputChange}
						className="login-input"
					/>
				</Form.Item>

				<div>
					<Button
						type="link"
						onClick={() => navigate("/forgot_password")}
						disabled={loading}
						style={{
							display: "flex",
							marginTop: 10,
							marginLeft: 10,
							marginBottom: 10,
						}}>
						Forgot password?
					</Button>
				</div>

				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						loading={loading}
						style={{ height: 40, marginTop: -20 }}
						block
						className="login-button">
						Login
					</Button>
				</Form.Item>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						color: "black",
					}}>
					<Paragraph>
						Don't have an account?
						<Button
							type="link"
							onClick={onToggle}
							disabled={loading}
							className="signup-text">
							Sign Up
						</Button>
					</Paragraph>
				</div>
			</Form>
		</div>
	);
};

export default LoginForm;
