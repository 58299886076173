import React, { useState } from "react";
import { Modal, Button, Upload, message, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAuth } from "../../common/useAuth";

const { Text } = Typography;

const ScreenResumeModal = ({ isVisible, onClose, id }) => {
	const { apiurl, token } = useAuth();
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [responseData, setResponseData] = useState(null);

	const handleUpload = (info) => {
		const uploadedFile = info.file.originFileObj;
		if (uploadedFile) {
			setFile(uploadedFile);
			setResponseData(null);
		}
	};

	const handleSubmit = async () => {
		if (!file) {
			message.error("Please upload a file before submitting!");
			return;
		}
		const formData = new FormData();
		formData.append("resume", file);

		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/screen-resume/${id}`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			setResponseData(data);
			message.success("Resume uploaded successfully!");
		} catch (error) {
			message.error("Failed to upload resume. Please try again.");
			console.error("Error uploading resume:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			destroyOnClose
			open={isVisible}
			title="Upload Your Resume"
			onCancel={onClose}
			footer={[
				<Button key="cancel" onClick={onClose}>
					Cancel
				</Button>,
				<Button
					key="submit"
					type="primary"
					loading={loading}
					onClick={handleSubmit}
					disabled={!file}>
					Submit
				</Button>,
			]}>
			<Upload
				beforeUpload={() => false}
				onChange={handleUpload}
				maxCount={1}
				showUploadList={{ showRemoveIcon: true }}
				accept=".pdf,.docx">
				<Button icon={<UploadOutlined />}>Click to Upload</Button>
			</Upload>

			{responseData && (
				<div style={{ marginTop: 20 }}>
					<Text strong>Response:</Text>
					<pre style={{ background: "#f6f6f6", padding: 10, borderRadius: 5 }}>
						{responseData}
					</pre>
				</div>
			)}
		</Modal>
	);
};

export default ScreenResumeModal;
