import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CompleteJobPost.css";
import { Button, Table, Modal, Select, message } from "antd";
import Main from "./Layout";
import { useAuth } from "../../common/useAuth";

const CompleteJobPost = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { apiurl, token } = useAuth();
	const [job, setJob] = useState(null);
	const [recruiters, setRecruiters] = useState([]);
	const [selectedRecruiter, setSelectedRecruiter] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const fetchJobDetails = async () => {
		if (token) {
			try {
				const response = await fetch(`${apiurl}/job-details?job_id=${id}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setJob(data);
			} catch (error) {
				console.error("Error fetching job details:", error);
			}
		}
	};
	useEffect(() => {
		fetchJobDetails();
	}, [token, id, apiurl]);

	const fetchRecruiters = async () => {
		try {
			const response = await fetch(`${apiurl}/agency/recruiters/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setRecruiters(data);
		} catch (error) {
			console.error("Error fetching recruiters:", error);
		}
	};

	const handleAssign = () => {
		setIsModalVisible(true);
		fetchRecruiters();
	};

	const handleChangeRecruiter = () => {
		setIsModalVisible(true);
		fetchRecruiters();
	};

	const handleSubmitRecruiter = async () => {
		if (!selectedRecruiter) {
			message.error("Please select a recruiter");
			return;
		}
		try {
			const response = await fetch(`${apiurl}/assign-recruiter/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ job_id: id, recruiter_id: selectedRecruiter }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success("Recruiter updated successfully");
				setIsModalVisible(false);
				fetchJobDetails();
			} else {
				message.error("Failed to update recruiter");
			}
		} catch (error) {
			console.error("Error updating recruiter:", error);
			message.error("An error occurred while updating the recruiter");
		}
	};

	const columns = [
		{
			title: "Round",
			dataIndex: "round_num",
			key: "round_num",
		},
		{
			title: "Interviewer Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Interviewer Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Type of Interview",
			dataIndex: "type_of_interview",
			key: "type_of_interview",
		},
	];

	return (
		<Main>
			<div className="job-details-manager-main">
				<h1>Job Details by {job?.username?.username}</h1>
				<div className="job-detail">
					<span>Job Title:</span>
					<div>{job?.job_title || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Job Description:</span>
					<div>{job?.job_description || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Job Department:</span>
					<div>{job?.job_department || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Primary Skills:</span>
					<div>{job?.primary_skills || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Secondary Skills:</span>
					<div>{job?.secondary_skills || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Years of Experience:</span>
					<div>{job?.years_of_experience || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>CTC:</span>
					<div>{job?.ctc || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Rounds of Interview:</span>
					<div>{job?.rounds_of_interview || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Job Location:</span>
					<div>{job?.job_location || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Job Type:</span>
					<div>{job?.job_type || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Job Level:</span>
					<div>{job?.job_level || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Qualifications:</span>
					<div>{job?.qualifications || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Timings:</span>
					<div>{job?.timings || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Other Benefits:</span>
					<div>{job?.other_benefits || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Working Days per Week:</span>
					<div>{job?.working_days_per_week || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Decision Maker:</span>
					<div>{job?.decision_maker || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Decision Maker Email:</span>
					<div>{job?.decision_maker_email || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Bond:</span>
					<div>{job?.bond || "N/A"}</div>
				</div>
				<div className="job-detail">
					<span>Rotational Shift:</span>
					<div>{job?.rotational_shift ? "Yes" : "No"}</div>
				</div>
				<hr />
				<h2>Interviewers Data</h2>
				<Table
					columns={columns}
					dataSource={job?.interview_details || []}
					rowKey="id"
					pagination={false}
				/>
				<hr />
				{job?.status === "opened" && (
					<div>
						<div>
							<h3>Job Assigned To..</h3>
							{!job.is_assigned ? (
								<Button onClick={handleAssign}>Assign Job to Staff</Button>
							) : (
								<>
									<h4 className="green">{job.is_assigned?.username}</h4>
									<Button onClick={handleChangeRecruiter}>
										Change the Staff
									</Button>
								</>
							)}
							<Button onClick={() => navigate(`/manager/edit_job/${id}`)}>
								Edit Job
							</Button>
						</div>
					</div>
				)}
			</div>

			<Modal
				title="Select Recruiter"
				open={isModalVisible}
				onOk={handleSubmitRecruiter}
				onCancel={() => setIsModalVisible(false)}
				okText="Submit"
				cancelText="Cancel">
				<Select
					style={{ width: "100%" }}
					placeholder="Select a recruiter"
					onChange={setSelectedRecruiter}
					value={selectedRecruiter}>
					{recruiters.map((recruiter) => (
						<Select.Option key={recruiter.id} value={recruiter.id}>
							{recruiter.username}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</Main>
	);
};

export default CompleteJobPost;
