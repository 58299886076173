import React, { useState } from "react";
import { Form, Input, Button, Tooltip, Steps, message } from "antd";
import {
	InfoCircleOutlined,
	UserOutlined,
	MailOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./forms.css";

const apiurl = process.env.REACT_APP_BACKEND_URL;

const ClientSignup = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [firstPartData, setFirstPartData] = useState({});

	const validateUsername = (_, value) => {
		if (!value || /^[a-zA-Z0-9_]+$/.test(value)) {
			return Promise.resolve();
		}
		return Promise.reject(
			new Error(
				"Username should contain only letters, numbers, and underscores."
			)
		);
	};

	const validateEmail = (_, value) => {
		if (!value || /\S+@\S+\.\S+/.test(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error("Please enter a valid email address."));
	};

	const steps = [
		{
			title: "Account Details",
			content: (
				<>
					<Form.Item
						label="Username"
						name="username"
						rules={[{ required: true, validator: validateUsername }]}
						validateTrigger="onBlur">
						<Input
							placeholder="Enter your username"
							prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							suffix={
								<Tooltip title="Username should contain only letters, numbers, and underscores">
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
						/>
					</Form.Item>

					<Form.Item
						label="Email"
						name="email"
						rules={[{ required: true, validator: validateEmail }]}
						validateTrigger="onBlur">
						<Input
							placeholder="Enter your email"
							prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
						/>
					</Form.Item>

					<Form.Item
						label="Name of Organization"
						name="name_of_organization"
						rules={[
							{
								required: true,
								message: "Please input the name of your organization!",
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Designation"
						name="designation"
						rules={[
							{ required: true, message: "Please input your designation!" },
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Contact Number"
						name="contact_number"
						rules={[
							{ required: true, message: "Please input your contact number!" },
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Website URL"
						name="website_url"
						rules={[
							{ required: true, message: "Please input your website URL!" },
						]}>
						<Input />
					</Form.Item>
				</>
			),
		},
		{
			title: "Company Information",
			content: (
				<>
					<Form.Item
						label="GST"
						name="gst"
						rules={[
							{ required: true, message: "Please input your GST number!" },
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Company PAN"
						name="company_pan"
						rules={[
							{
								required: true,
								message: "Please input your company PAN number!",
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Company Address"
						name="company_address"
						rules={[
							{ required: true, message: "Please input your company address!" },
						]}>
						<Input.TextArea rows={4} />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: "Enter password" }]}>
						<Input.Password />
					</Form.Item>

					<Form.Item
						label="Confirm Password"
						name="confirm_password"
						rules={[
							{ required: true, message: "Re-enter password" },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue("password") === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error("Passwords do not match"));
								},
							}),
						]}>
						<Input.Password />
					</Form.Item>
				</>
			),
		},
	];

	const handleSubmit = async (values) => {
		setLoading(true);
		const combinedValues = { ...firstPartData, ...values };
		try {
			const response = await fetch(`${apiurl}/signup/client/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(combinedValues),
			});

			const userData = await response.json();
			if (!response.ok) {
				throw new Error(userData.error || "Failed to submit");
			}

			message.success("Signup successful! Please Login");
			navigate("/login");
		} catch (error) {
			message.info(error.message);
		} finally {
			setLoading(false);
		}
	};

	const handleNext = () => {
		form
			.validateFields()
			.then((values) => {
				setFirstPartData(values);
				setCurrentStep(currentStep + 1);
			})
			.catch((info) => {
				console.log("Validation Failed:", info);
			});
	};

	const handlePrevious = () => {
		setCurrentStep(currentStep - 1);
	};

	return (
		<div>
			<div className="topnav">
				<img
					src="../src/media/ga_orgsync.png"
					alt="Home page img"
					width={90}
					height={70}
					style={{ marginLeft: 30, marginTop: 5 }}
				/>
			</div>
			<h1 className="center heading1">
				Unlocking Talent, Empowering Companies
			</h1>

			<div className="client-signup">
				<div>
					<Steps current={currentStep} style={{ marginBottom: 30 }}>
						{steps.map((step, index) => (
							<Steps.Step key={index} title={step.title} />
						))}
					</Steps>

					<Form
						form={form}
						layout="vertical"
						onFinish={
							currentStep === steps.length - 1 ? handleSubmit : handleNext
						}
						style={{ width: 500, margin: "0 auto" }}>
						{steps[currentStep].content}

						<Form.Item>
							{currentStep > 0 && (
								<Button onClick={handlePrevious} style={{ marginRight: 8 }}>
									Previous
								</Button>
							)}
							{currentStep < steps.length - 1 ? (
								<Button type="primary" onClick={handleNext}>
									Next
								</Button>
							) : (
								<Button type="primary" htmlType="submit" loading={loading}>
									Signup
								</Button>
							)}
						</Form.Item>
					</Form>
				</div>
				<div className="home_img center">
					<img
						src="../src/media/client_signup.png"
						alt="Client signup "
						className="home-img"
					/>
				</div>
			</div>
		</div>
	);
};

export default ClientSignup;
